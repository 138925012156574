export const HEALTH_WELLNESS_SEARCH_FILTER = [
  {
    name: 'Oral Medicine Administration',
    slug: 'oral_medicine_administration',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Injected Medicine Administration',
    slug: 'injected_medicine_administration',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
];
