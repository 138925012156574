import { z } from 'zod';
import { InitialBookingFiltersState } from '../../../../context';

export const validateFilters = (filters: InitialBookingFiltersState['filters']) => {
  const filtersSchema = z.object({
    latitude: z.number(),
    longitude: z.number(),
    petAge: z.string().optional().nullable(),
    petSize: z.string().optional().nullable(),
    startDate: z.string().min(1),
    endDate: z.string().min(1),
    service: z.string(),
    distance: z.number(),
    priceMin: z.number().nullable(),
    priceMax: z.number().nullable(),
    walkerProfileFlags: z.record(z.string(), z.boolean()),
    page: z.number(),
  });

  return filtersSchema.safeParse(filters);
};

export type LandingPageFilters = InitialBookingFiltersState['filters'] & {
  selectedDays: string[];
}

export const validateLandingFilters = (filters: LandingPageFilters, validatePets = true) => {
  const isWalkOrDropIn = filters.service === 'drop-in' || filters.service === 'walk';
  
  const filtersSchema = z.object({
    latitude: z.number(),
    longitude: z.number(),
    ...(isWalkOrDropIn ? {
      selectedDays: z.array(z.string()).min(1),
    } : {
      startDate: z.string().min(1),
      endDate: z.string().min(1),
    }),
    service: z.string(),
    distance: z.number(),
    priceMin: z.number().nullable(),
    priceMax: z.number().nullable(),
    walkerProfileFlags: z.record(z.string(), z.boolean()),
    page: z.number(),
    dogsNumber: z.number().min(0).optional(),
    catsNumber: z.number().min(0).optional(),
  });

  const schemaWithRefinement = validatePets
    ? filtersSchema.refine((data) => data.dogsNumber > 0 || data.catsNumber > 0)
    : filtersSchema;

  return schemaWithRefinement.safeParse(filters);
};

export const phoneRegexV1 = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const phoneRegexV2 = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
