export const HOME_PREFERENCES_SEARCH_FILTER = [
  {
    name: 'House',
    slug: 'lives_in_house',
    include: true,
    services: ['boarding'],
  },
  {
    name: 'Apartment',
    slug: 'lives_in_apartment',
    include: true,
    services: ['boarding'],
  },
  {
    name: 'Fenced Yard',
    slug: 'fenced_yard',
    include: true,
    services: ['boarding'],
  },
  {
    name: 'Allowed on bed',
    slug: 'dogs_allowed_on_bed',
    include: true,
    services: ['boarding'],
  },
  {
    name: 'Allowed on furniture',
    slug: 'dogs_allowed_on_furniture',
    include: true,
    services: ['boarding'],
  },
  {
    name: 'No cats',
    slug: 'has_cat',
    include: false,
    services: ['boarding'],
  },
  {
    name: 'No children',
    slug: 'has_children',
    include: false,
    services: ['boarding'],
  },
  {
    name: 'Non Smoking',
    slug: 'non_smoking_household',
    include: true,
    services: ['boarding'],
  },
  {
    name: 'Unfenced Yard',
    slug: 'unfenced_yard',
    include: true,
    services: ['boarding'],
  },
  {
    name: 'Home Full Time',
    slug: 'home_full_time',
    include: true,
    services: ['boarding'],
  },
];
