import React from 'react';
import { cn } from '../../utils/tailwind';
type Props = {
  text: string;
  className?: string;
};
export const SectionSubTitle = ({
  text,
  className
}: Props) => <div className={cn('mb-4 text-center text-zinc-600 text-base font-medium leading-9 tracking-tight', className)} data-sentry-component="SectionSubTitle" data-sentry-source-file="index.tsx">
    {text}
  </div>;