import { formatISO } from 'date-fns';
import queryString from 'query-string';
import { WalkerProfilesQueryParams } from '..';
import { ALL_WALKER_PROFILE_FLAGS } from '../../../../components/WalkerFilterFlags/data/profile-flags';

type SortBy = {
  column: string;
  dir?: string;
}

export type WalkerServiceType =
  | 'boarding'
  | 'walk'
  | 'training'
  | 'home-care'
  | 'grooming'
  | 'sitting'
  | 'drop-in'
  | 'training'
  | 'transportation'
  | 'excursion'
  | 'medical'
  | 'misc';

export const createSearchWalkersQuery = (
  {
    latitude,
    longitude,
    distance = 10,
    page = 1,
    perPage = 20,
    service = 'boarding',
    datetimeMin,
    datetimeMax,
    priceMin = 1000,
    priceMax = 9000,
    promoted = false,
    sortBy = [{ column: 'recommended' }] as [SortBy],
    walkerProfileFlags,
  }: WalkerProfilesQueryParams,
) => {
  const requiredWalkerProfileFlags = ALL_WALKER_PROFILE_FLAGS.reduce((acc, item) => {
    if (item.include && walkerProfileFlags?.[item?.slug]) {
      acc.push(item.slug);
    }
    return acc;
  }, []);

  const excludedWalkerProfileFlags = ALL_WALKER_PROFILE_FLAGS.reduce((acc, item) => {
    if (!item.include && walkerProfileFlags?.[item?.slug]) {
      acc.push(item.slug);
    }
    return acc;
  }, []);

  const sortByQueryString = sortBy.map((obj, i) => Object.keys(obj).map((key) => `sortBy[${i}][${key}]=${obj[key]}`).join('&')).join('&');
  const url = queryString.stringifyUrl({
    url: '/api/v5/web/providers/search',
    query: {
      latitude,
      longitude,
      distanceMiles: distance,
      page,
      perPage,
      service,
      priceMin,
      priceMax,
      promoted,
      datetimeMin: datetimeMin ? formatISO(new Date(datetimeMin)) : undefined,
      datetimeMax: datetimeMin ? formatISO(new Date(datetimeMax)) : undefined,
      requiredWalkerProfileFlags,
      excludedWalkerProfileFlags,
    },
  }, { arrayFormat: 'bracket' });
  const urlAndSortByQueryString = `${url}&${sortByQueryString}`;
  return urlAndSortByQueryString;
};
