// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const snakeCaseToCamelCaseRecursive = (obj: Record<string, any>) => {
  if (typeof obj !== 'object' || obj === null) {
    // If obj is not an object or is null, return it as is
    return obj;
  }

  if (Array.isArray(obj)) {
    // If obj is an array, apply the function to each element
    return obj.map((item) => snakeCaseToCamelCaseRecursive(item));
  }

  // If obj is an object, recursively convert its keys and values
  const camelCaseObj = {};

  Object.keys(obj).forEach((key) => {
    const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    camelCaseObj[camelKey] = snakeCaseToCamelCaseRecursive(obj[key]);
  });

  return camelCaseObj;
};
