export const QUERY_KEYS = {
  GEOCODING_SEARCH: 'geocoding-search',
  REVERSE_GEOCODING_SEARCH: 'reverse-geocoding-search',
} as const;

export const MUTATION_KEYS = {
  POST_VERIFY_CODE: 'post-verify-code',
  POST_CREATE_MEDIA_UPLOAD_URL: 'post-create-media-upload-url',
} as const;

export type QueryKeys = typeof QUERY_KEYS[keyof typeof QUERY_KEYS];
export type MutationKeys = typeof MUTATION_KEYS[keyof typeof MUTATION_KEYS];
