import React from 'react';
import { cn } from '../../../../../utils/tailwind/cn';
type Props = {
  text?: string;
  className?: string;
};
export const SectionTitle = ({
  text,
  className
}: Props) => <h2 className={cn('text-black text-center w-full text-wrap-balance', 'mb-6 md:mb-[36px] lg:mb-[54px] px-2 md:px-0', 'section-title', className)} data-sentry-component="SectionTitle" data-sentry-source-file="SectionTitle.tsx">
    {text}
  </h2>;