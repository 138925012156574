export const isObject = (value: unknown) => typeof value === 'object'
  && value !== null && !Array.isArray(value) && !(value instanceof Function);

const isString = (value: unknown) => typeof value === 'string';

export const getErrorMessage = (error: unknown): string | null => {
  if (!isObject(error)) {
    if (isString(error)) {
      return error as string;
    }
    return null;
  }

  const errorObject = error as Record<string, unknown>;

  if ('message' in errorObject) {
    if (typeof errorObject.message === 'string') {
      return errorObject.message;
    }
  }

  let errorMessage: string | null = null;

  Object.keys(errorObject).forEach((key) => {
    const value = errorObject[key];
    const message = getErrorMessage(value);
    if (message) {
      errorMessage = message;
    }
  });

  return errorMessage;
};
