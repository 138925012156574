/* eslint-disable camelcase */
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import {
  initialWalkerProfileFlags,
  WalkerProfileFlags,
} from '../../../../components/WalkerFilterFlags/data/profile-flags';
import {
  PetAge,
  PetSize,
  PetType,
} from '../../components/landing/hero/data';
import { AvailableServiceType } from '../../components/whippet/data';
import { OwnerAddress } from '../../types/putOwner';

export const initialFilters = {
  latitude: null,
  longitude: null,
  page: 1,
  petAge: null,
  petSize: null,
  petType: null,
  dogsNumber: 0,
  catsNumber: 0,
  startDate: null,
  endDate: null,
  service: 'boarding',
  distance: 10,
  priceMin: 10,
  priceMax: 150,
  walkerProfileFlags: initialWalkerProfileFlags,
} as const;

export type InitialBookingFiltersState = {
  filters: {
    latitude?: number;
    longitude?: number;
    latitudeMarkerHome?: number;
    longitudeMarkerHome?: number;
    petSize?: PetSize['slug'];
    petAge?: PetAge['slug'];
    petType?: PetType['slug'];
    dogsNumber?: number;
    catsNumber?: number;
    startDate?: string;
    endDate?: string;
    service?: AvailableServiceType['value'];
    distance?: number;
    priceMin?: number;
    priceMax?: number;
    walkerProfileFlags?: WalkerProfileFlags;
    page?: number;
  };
  search: {
    enabled?: boolean;
    address?: OwnerAddress;
    coordinates?: [number, number];
  };
  map: {
    expandedIndex?: number;
    isHover?: boolean;
    coordinates?: [number, number];
  };
}

export const initialBookingFiltersState: InitialBookingFiltersState = {
  filters: initialFilters,
  search: {
    enabled: true,
    coordinates: null,
    address: {
      addressLine1: null,
      addressLine2: null,
      city: null,
      postalCode: null,
      administrativeAreaLevel1: null,
      country: null,
    },
  },
  map: {
    expandedIndex: null,
    isHover: false,
  },
};

type FilterActions = {
  setFilters: (values: typeof initialBookingFiltersState.filters) => void;
  setSearch: (values: typeof initialBookingFiltersState.search) => void;
  setMap: (values: typeof initialBookingFiltersState.map) => void;
}

type FiltersStore = FilterActions & InitialBookingFiltersState;

export const useFiltersStore = create<FiltersStore>()(
  persist(
    (set) => ({
      ...initialBookingFiltersState,
      setFilters: (newFilters) => set(
        (state) => ({ filters: { ...state.filters, ...newFilters } })),
      setSearch: (newSearchState) => set(
        (state) => ({ search: { ...state.search, ...newSearchState } })),
      setMap: (newMapState) => set(
        (state) => ({ map: { ...state.map, ...newMapState } })),
    }),
    {
      name: 'wag-booking-filters',
      version: 1,
    },
  ),
);
