import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Script from 'next/script';
import { ErrorBoundary } from 'react-error-boundary';
import clsx from 'clsx';
import { Footer } from './components/footer';
import { Header } from './components/header';
import { queryClient } from './context/react-query';
import { BoneSpinner } from './components/BoneSpinner';
import { TWButton } from '../../tw-components/ui/button';
import { logError } from './helpers/logError';
type Props = {
  children: React.ReactNode;
  showFooter?: boolean;
  showHeader?: boolean;
};
export const BookingLayout = ({
  showFooter = true,
  showHeader = true,
  children
}: Props) => <ErrorBoundary fallbackRender={({
  resetErrorBoundary
}) => <div role="alert" className={clsx('w-screen h-screen bg-grayBg flex items-center justify-center flex-col pb-[20%]')}>
        <h1>Something went wrong</h1>
        <TWButton className="max-w-[400px] mt-6" size="pill" variant="booking-journey" onClick={() => {
    resetErrorBoundary();
  }}>
          Reset
        </TWButton>
      </div>} onError={error => {
  logError({
    error
  });
}} data-sentry-element="ErrorBoundary" data-sentry-component="BookingLayout" data-sentry-source-file="index.tsx">
    <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="index.tsx">
      <Script type="text/javascript" src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" crossOrigin="anonymous" defer data-sentry-element="Script" data-sentry-source-file="index.tsx" />
      {showHeader && <Header />}
      <BoneSpinner data-sentry-element="BoneSpinner" data-sentry-source-file="index.tsx" />
      {children}
      {showFooter && <Footer />}
      <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="index.tsx" />
    </QueryClientProvider>
  </ErrorBoundary>;