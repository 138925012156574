import React from 'react';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';
import { descriptionItems } from './data';
import { cn } from '../../../../../utils/tailwind';
export type DescriptionProps = {
  sectionTitleClassName?: string;
  sectionContainerClassName?: string;
  descriptionTitle?: string;
  items?: typeof descriptionItems;
};
export const Description = ({
  descriptionTitle,
  sectionTitleClassName,
  sectionContainerClassName,
  items = descriptionItems
}: DescriptionProps) => <SectionContainer className={cn(sectionContainerClassName)} data-sentry-element="SectionContainer" data-sentry-component="Description" data-sentry-source-file="index.tsx">
    <SectionTitle text={descriptionTitle} className={cn(sectionTitleClassName)} data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
    <div className="flex flex-wrap justify-between w-[90%] md:w-[94%] lg:w-full mx-auto">
      {items.map(item => {
      const Icon = item.icon;
      return <div key={item.title} className="flex w-full md:w-[47%] mb-8 last:mb-0 lg:mb-12 md:[&:nth-child(5)]:!mb-0 md:[&:nth-child(6)]:!mb-0">
            <Icon />
            <div>
              <h4 className="mb-2 text-darkGray">{item.title}</h4>
              <p className="text-wagGray">{item.description}</p>
            </div>
          </div>;
    })}
    </div>
  </SectionContainer>;