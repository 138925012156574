import { QueryKey } from '@tanstack/react-query';

import { MutationKeys, QueryKeys } from '../constants/query-keys';

const SKIP_ERRORS: Array<QueryKeys | MutationKeys> = [
  'geocoding-search', 'reverse-geocoding-search', 'post-verify-code',
];

export const getQueryError = (queryKeyArray: QueryKey | MutationKeys) => {
  let filterError = false;
  let queryErrorInfo = '';

  const queryKey = queryKeyArray[0] as QueryKeys;
  queryErrorInfo = `queryKey: ${queryKey}`;
  const queryInput = queryKeyArray[1];

  if (queryInput) {
    queryErrorInfo += ` queryInput: ${queryInput}`;
  }

  if (queryKey && SKIP_ERRORS.includes(queryKey)) {
    filterError = true;
  }

  return {
    filterError,
    queryInfo: queryErrorInfo,
  };
};
