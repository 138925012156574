import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { X } from 'lucide-react';
import { cn } from '../../utils/tailwind/cn';
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = ({
  ...props
}: DialogPrimitive.DialogPortalProps) => <DialogPrimitive.Portal {...props} data-sentry-element="unknown" data-sentry-component="DialogPortal" data-sentry-source-file="dialog.tsx" />;
DialogPortal.displayName = DialogPrimitive.Portal.displayName;
const DialogOverlay = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>>(({
  className,
  ...props
}, ref) => <DialogPrimitive.Overlay ref={ref} className={clsx('fixed inset-0 z-50 bg-black/50 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0', className)} {...props} />);
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
  showBg?: boolean;
  overlayClassName?: string;
  customCloseIcon?: () => JSX.Element;
  closeButtonClassName?: string;
  closeButtonIconClassName?: React.ReactNode;
}>(({
  className,
  children,
  overlayClassName,
  closeButtonClassName,
  closeButtonIconClassName,
  customCloseIcon,
  showBg = false,
  ...props
}, ref) => {
  const CloseIcon = customCloseIcon || X;
  return <DialogPortal>
            <DialogOverlay className={cn(showBg && 'bg-grayBg', overlayClassName)} />
            <DialogPrimitive.Content ref={ref} className={cn('fixed left-[50%] top-[50%] z-50 grid w-[92%] rounded-lg', 'translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 p-4 md:p-6', 'shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out', 'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0', 'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95', 'data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]', 'data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg', className)} {...props}>
              {children}
              <DialogPrimitive.Close id="close-modal-button" className={cn('absolute right-2 top-3 md:right-4 md:top-4 rounded-sm opacity-70 ', 'ring-offset-white transition-opacity hover:opacity-100', 'focus:outline-none focus:ring-2 focus:ring-slate-950', 'focus:ring-offset-2 disabled:pointer-events-none modal-close', 'data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500', closeButtonClassName)}>
                <CloseIcon className={cn('h-6 w-6 text-darkGray/50 md:h-6 md:w-6 lg:w-7 lg:h-7', closeButtonIconClassName)} />
                <span className="sr-only">Close</span>
              </DialogPrimitive.Close> 
            </DialogPrimitive.Content>
          </DialogPortal>;
});
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={clsx('flex flex-col space-y-1.5 text-center sm:text-left', className)} {...props} data-sentry-component="DialogHeader" data-sentry-source-file="dialog.tsx" />;
DialogHeader.displayName = 'DialogHeader';
const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={clsx('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} data-sentry-component="DialogFooter" data-sentry-source-file="dialog.tsx" />;
DialogFooter.displayName = 'DialogFooter';
const DialogTitle = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>>(({
  className,
  ...props
}, ref) => <DialogPrimitive.Title ref={ref} className={clsx('text-lg font-semibold leading-none tracking-tight', className)} {...props} />);
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Description>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>>(({
  className,
  ...props
}, ref) => <DialogPrimitive.Description ref={ref} className={clsx('text-sm text-slate-500', className)} {...props} />);
DialogDescription.displayName = DialogPrimitive.Description.displayName;
export { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription };