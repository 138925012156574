import React from 'react';
export const CupIcon = () => <div className="mr-4" data-sentry-component="CupIcon" data-sentry-source-file="CupIcon.tsx">
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none" data-sentry-element="svg" data-sentry-source-file="CupIcon.tsx">
      <path stroke="hsl(var(--twc-cup))" strokeWidth="2.125" d="M17 22.667c-8.16 0-9.605-8.132-9.86-14.583-.072-1.794-.108-2.692.566-3.52.673-.831 1.48-.967 3.093-1.24 2.05-.335 4.124-.5 6.2-.49 2.528 0 4.61.222 6.202.49 1.613.273 2.42.409 3.093 1.24.675.83.638 1.726.567 3.52-.255 6.45-1.7 14.583-9.86 14.583H17Z" data-sentry-element="path" data-sentry-source-file="CupIcon.tsx" />
      <path stroke="hsl(var(--twc-cup))" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.125" d="m15.583 11.333 2.125-2.125v5.667" data-sentry-element="path" data-sentry-source-file="CupIcon.tsx" />
      <path stroke="hsl(var(--twc-cup))" strokeWidth="2.125" d="m26.917 7.083 1.344.448c1.402.467 2.104.701 2.505 1.258.4.557.4 1.296.4 2.774v.103c0 1.218 0 1.83-.293 2.328-.293.498-.827.795-1.892 1.388l-4.19 2.326M7.084 7.083l-1.344.448c-1.403.467-2.104.701-2.505 1.258-.4.557-.4 1.298-.4 2.774v.103c0 1.218 0 1.83.292 2.328.294.498.828.795 1.893 1.388l4.19 2.326" data-sentry-element="path" data-sentry-source-file="CupIcon.tsx" />
      <path stroke="hsl(var(--twc-cup))" strokeLinecap="round" strokeWidth="2.125" d="M17 22.667v4.25" data-sentry-element="path" data-sentry-source-file="CupIcon.tsx" />
      <path stroke="hsl(var(--twc-cup))" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.125" d="M21.958 31.167h-9.916l.481-2.403a1.417 1.417 0 0 1 1.389-1.139h6.176a1.417 1.417 0 0 1 1.389 1.139l.481 2.403Z" data-sentry-element="path" data-sentry-source-file="CupIcon.tsx" />
      <path stroke="hsl(var(--twc-cup))" strokeLinecap="round" strokeWidth="2.125" d="M25.5 31.167h-17" data-sentry-element="path" data-sentry-source-file="CupIcon.tsx" />
    </svg>
  </div>;