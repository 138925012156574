'use client';

import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import boneSpinner from '../assets/bone-spinner.svg';
import { useBookingStore } from '../context';
import { MUTATION_KEYS } from '../constants/query-keys';
export const IS_BROWSER = typeof window !== 'undefined';
type Props = {
  className?: string;
};
export const BoneSpinner = ({
  className
}: Props) => {
  const isFetching = useIsFetching({
    predicate: query => {
      const queryKey = String(query?.queryKey?.[0]);
      // Disable global spinner when fetching these queries
      return !['geocoding-address', 'geocoding-search', 'reverse-geocoding-search', 'service-type-preferences', 'estimated-price', 'owner-address', 'offered-services-category', 'pet-breeds', 'pet-questionnaire', 'available-services', MUTATION_KEYS.POST_CREATE_MEDIA_UPLOAD_URL].includes(queryKey);
    }
  });
  const isMutating = useIsMutating({
    predicate: mutation => {
      const mutationKey = String(mutation?.options?.mutationKey?.[0]);
      const filterMutationKeysArr = [MUTATION_KEYS.POST_CREATE_MEDIA_UPLOAD_URL] as string[];
      return !filterMutationKeysArr.includes(mutationKey);
    }
  });
  const step = useBookingStore(state => state.journey.step);
  const modalIsOpen = useBookingStore(state => state.caregiverModal.isOpen);
  const showSpinner = (isFetching || isMutating) && (step !== 'Profile' || !modalIsOpen);
  useEffect(() => {
    if (showSpinner) {
      document.body.style.pointerEvents = 'none';
    } else {
      document.body.style.pointerEvents = 'auto';
    }
  }, [showSpinner]);
  return showSpinner && IS_BROWSER ? createPortal(<div className={clsx('absolute inset-0 w-full h-full flex items-center justify-center', 'z-[100] pointer-events-none')}>
      <div className="p-6 bg-black/80 rounded-xl">
        <img src={boneSpinner} alt="bone spinner" className={clsx('w-12 h-12 md:w-14 md:h-14 animate-[spin_.8s_ease-in-out_infinite]', className)} />
      </div>
    </div>, document.body) : null;
};