import React, { ReactNode } from 'react';
import { cn } from '../../../../../utils/tailwind/cn';
type Props = {
  className?: string;
  children: ReactNode;
};
export const SectionContainer = ({
  className,
  children
}: Props) => <div className={cn('max-w-[1050px] mx-auto my-[30px] md:mb-[100px] lg:mb-[140px] relative', className)} data-sentry-component="SectionContainer" data-sentry-source-file="SectionContainer.tsx">
    {children}
  </div>;