export const TRAINING_EXPERIENCE_SEARCH_FILTER = [
  {
    name: 'Puppy',
    slug: 'puppy_training',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Socialization',
    slug: 'socialization_training',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Behavioral',
    slug: 'behavioral_training',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Obedience',
    slug: 'obedience_training',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Potty',
    slug: 'potty_training',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Agility',
    slug: 'agility_training',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Therapy',
    slug: 'therapy_training',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
];

export type WalkerFlag = typeof TRAINING_EXPERIENCE_SEARCH_FILTER[number];
