import clsx from 'clsx';
import React from 'react';
import { Logo } from './Logo';
import { Menu } from './Menu';
// import { UserMenu } from './UserMenu';

import SitterHearth from '../../assets/sitter-hearth.svg';
export const Header = () => <header className="h-[60px] md:h-[70px] py-2 flex w-[94%] mx-auto items-center justify-center relative" data-sentry-component="Header" data-sentry-source-file="index.tsx">
    <Logo data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
    <div className="hidden md:flex items-center justify-center right-[130px] absolute">
      <img src={SitterHearth} alt="sitter-hearth" className="w-[20px] h-[18px]" />

      <a className="text-wagBlue underline text-[14px] font-[500] ml-2" href="https://wagwalking.com/dog-sitter" aria-label="become-a-sitter">
        Become a Sitter
      </a>

    </div>
    <div className={clsx('justify-self-end absolute right-0 flex items-center justify-between', ' p-1 pl-[12px]')}>
      <Menu data-sentry-element="Menu" data-sentry-source-file="index.tsx" />
      {/* <UserMenu /> */}
    </div>
  </header>;