import PetCareIcon from '../../../../assets/pet-care.svg';
import PickCaregiverIcon from '../../../../assets/pick-caregiver.svg';
import BookPayIcon from '../../../../assets/book-pay.svg';

// Import new service-specific icons
import dogBoarding1 from '../../../../assets/how-to-book/dog-boarding/dog-boarding-1.svg';
import dogBoarding2 from '../../../../assets/how-to-book/dog-boarding/dog-boarding-2.svg';
import dogBoarding3 from '../../../../assets/how-to-book/dog-boarding/dog-boarding-3.svg';

import dogSitting1 from '../../../../assets/how-to-book/dog-sitting/dog-sitting-1.svg';
import dogSitting2 from '../../../../assets/how-to-book/dog-sitting/dog-sitting-2.svg';
import dogSitting3 from '../../../../assets/how-to-book/dog-sitting/dog-sitting-3.svg';

import catSitting1 from '../../../../assets/how-to-book/cat-sitting/cat-sitting-1.svg';
import catSitting2 from '../../../../assets/how-to-book/cat-sitting/cat-sitting-2.svg';
import catSitting3 from '../../../../assets/how-to-book/cat-sitting/cat-sitting-3.svg';

import walk1 from '../../../../assets/how-to-book/walk/walk-1.svg';
import walk2 from '../../../../assets/how-to-book/walk/walk-2.svg';
import walk3 from '../../../../assets/how-to-book/walk/walk-3.svg';

import dropIn1 from '../../../../assets/how-to-book/drop-in/drop-in-1.svg';
import dropIn2 from '../../../../assets/how-to-book/drop-in/drop-in-2.svg';
import dropIn3 from '../../../../assets/how-to-book/drop-in/drop-in-3.svg';

export type HowToBookItems = Array<{
  icon: string; // fallback icon
  icons?: {
    boarding: string;
    sitting: {
      dog: string;
      cat: string;
    };
    walk: string;
    'drop-in': string;
  };
  title: string;
  description: string;
}>;

export const howToBookItems: HowToBookItems = [
  {
    icon: PetCareIcon,
    icons: {
      boarding: dogBoarding1,
      sitting: {
        dog: dogSitting1,
        cat: catSitting1
      },
      walk: walk1,
      'drop-in': dropIn1
    },
    title: 'Set your schedule',
    description: "Whether it's a weekend or a few weeks, select your days and we'll fit your schedule",
  },
  {
    icon: PickCaregiverIcon,
    icons: {
      boarding: dogBoarding2,
      sitting: {
        dog: dogSitting2,
        cat: catSitting2
      },
      walk: walk2,
      'drop-in': dropIn2
    },
    title: 'Meet your match',
    description: 'Select from a host of trusted, Wag!-approved Pet Caregivers in your area',
  },
  {
    icon: BookPayIcon,
    icons: {
      boarding: dogBoarding3,
      sitting: {
        dog: dogSitting3,
        cat: catSitting3
      },
      walk: walk3,
      'drop-in': dropIn3
    },
    title: 'Book and pay for your service',
    description: 'Receive pictures and videos of your pet enjoying playtime, meals, and snuggles (when requested)',
  },
];

export const howToBookWalkingItems: HowToBookItems = [
  {
    icon: PetCareIcon,
    icons: {
      boarding: dogBoarding1,
      sitting: dogSitting1,
      walk: walk1,
      'drop-in': dropIn1
    },
    title: 'Set your schedule',
    description: 'Available on-demand or pre-scheduled, select the date and time that best suits your pups needs',
  },
  {
    icon: PickCaregiverIcon,
    icons: {
      boarding: dogBoarding2,
      sitting: dogSitting2,
      walk: walk2,
      'drop-in': dropIn2
    },
    title: 'Get their steps in',
    description: 'Choose between a 20-minute, 30-minute, or 60-minute GPS tracked stroll for your dog',
  },
  {
    icon: BookPayIcon,
    icons: {
      boarding: dogBoarding3,
      sitting: dogSitting3,
      walk: walk3,
      'drop-in': dropIn3
    },
    title: 'Follow along',
    description: 'Receive live pictures and videos of your pup enjoying time outside with their walker',
  },
];
