'use client';

import React, { useState } from 'react';
import { ChevronRightIcon } from 'lucide-react';
import { TWButton } from '../../../../../tw-components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../../../tw-components/ui/tooltip';
import { cn } from '../../../../../utils/tailwind/cn';
type AnchorProps = {
  onClick?: () => void;
  isDisabled?: boolean;
  containerClassName?: string;
  tooltipText?: string;
  cySelector?: string;
  tooltipTriggerClassName?: string;
  chevronClassName?: string;
  variant?: 'default' | 'booking-journey' | 'booking-journey-secondary' | 'calendar-booking-journey' | 'partner';
  searchText?: string;
};
export const FindCaregiverButton = ({
  onClick,
  isDisabled,
  containerClassName,
  tooltipText,
  variant = 'booking-journey',
  tooltipTriggerClassName,
  chevronClassName,
  cySelector,
  searchText = 'Find a local Pet Caregiver'
}: AnchorProps) => {
  const [open, setOpen] = useState(false);
  const tooltipEnabled = isDisabled && !!tooltipText;
  return <TooltipProvider delayDuration={100} data-sentry-element="TooltipProvider" data-sentry-component="FindCaregiverButton" data-sentry-source-file="FindCaregiverButton.tsx">
      <Tooltip open={open} data-sentry-element="Tooltip" data-sentry-source-file="FindCaregiverButton.tsx">
        <TooltipTrigger asChild className={cn(isDisabled ? 'cursor-help' : 'cursor-pointer', 'flex items-center justify-center')} data-sentry-element="TooltipTrigger" data-sentry-source-file="FindCaregiverButton.tsx">
          <span onMouseEnter={() => {
          if (tooltipEnabled) {
            setOpen(true);
          }
        }} onFocus={() => {
          if (tooltipEnabled) {
            setOpen(true);
          }
        }} onBlur={() => {
          setOpen(false);
        }} onMouseLeave={() => {
          setOpen(false);
        }} className={cn(tooltipTriggerClassName)}>
            <TWButton size="pill" variant={variant} data-cy={cySelector} className={cn('mx-auto md:px-8 lg:mr-0', 'hover:opacity-95', '!w-full', containerClassName)} disabled={isDisabled} onClick={onClick} data-sentry-element="TWButton" data-sentry-source-file="FindCaregiverButton.tsx">
              {searchText}
              <ChevronRightIcon className={cn('w-4 h-4 ml-2', isDisabled ? 'text-darkGray/50' : 'text-white', chevronClassName)} strokeWidth={3} data-sentry-element="ChevronRightIcon" data-sentry-source-file="FindCaregiverButton.tsx" />
            </TWButton>
          </span>

        </TooltipTrigger>

        {tooltipEnabled && <TooltipContent side="top" className="border border-gray200 relative -bottom-[36px]">
            <p className="text-[12px] text-darkGray font-[400]">
              {tooltipText}
            </p>
          </TooltipContent>}
      </Tooltip>
    </TooltipProvider>;
};