/* eslint-disable no-console */
import { QueryKey } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { NODE_ENV } from '../../../constants/app';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { rollbarSkipPaths } from '../../../utils/rollbarConfig';

import { getQueryError } from './getQueryError';
import { handleError } from './handleError';

type LogErrorProps = {
  error: AxiosError | Error | unknown;
  errorInfo?: unknown;
  queryKey?: QueryKey;
}

export const logRollbarError = (errorMessage: string) => {
  const { Rollbar } = window;
  if (Rollbar && errorMessage) {
    Rollbar.error(errorMessage);
  }
};

export const logError = ({ error, errorInfo, queryKey }: LogErrorProps) => {
  const skipLogging = rollbarSkipPaths.some(path => window.location.pathname.startsWith(path));
  if (typeof window !== 'undefined' && skipLogging) {
    return {};
  }

  let errorDetail = '';
  let skipError = false;
  if (queryKey) {
    const { filterError, queryInfo } = getQueryError(queryKey);
    skipError = filterError;
    errorDetail = queryInfo;
  }

  if (skipError) return {};
  const errorMessage = getErrorMessage(error);
  const errorInfoMessage = getErrorMessage(errorInfo);
  if ((error as AxiosError)?.response?.data) {
    errorDetail = handleError(error as AxiosError);
  }
  const fullErrorMessage = `${errorMessage}
    ${errorDetail && errorDetail !== errorMessage && ` - ${errorDetail}`}
    ${errorInfo && errorInfo !== errorMessage && `- ${errorInfoMessage}`}
  `;

  if (NODE_ENV === 'development') {
    console.error('LogError, error', error);
    console.error('LogError, message', fullErrorMessage);
  } else {
    logRollbarError(fullErrorMessage);
  }

  return {
    errorMessage,
    errorInfoMessage,
    errorDetail,
    fullErrorMessage,
  };
};
