export const PET_EXPERIENCE_SEARCH_FILTER = [
  {
    name: 'High energy pets',
    slug: 'high_energy_dogs',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Senior pets',
    slug: 'senior_dog_care',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Puppies',
    slug: 'puppy_care',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Dogs who pull',
    slug: 'dogs_who_pull',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Special needs pets',
    slug: 'special_needs_dog_care',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Fast walker',
    slug: 'fast_walker',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Runs with dogs',
    slug: 'runs_with_dogs',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
  {
    name: 'Dog first aid',
    slug: 'dog_first_aid',
    include: true,
    services: ['sitting', 'boarding', 'walk', 'drop-ins', 'specialty'],
  },
];
