import React from 'react';
import WagApp from '../../../assets/wag_app_image.png';
import { cn } from '../../../../../utils/tailwind';
type Props = {
  imgClassName?: string;
};
export const AppImage = ({
  imgClassName
}: Props) => <picture data-sentry-element="picture" data-sentry-component="AppImage" data-sentry-source-file="AppImage.tsx">
    <source media="(width: 500px, height: 500px)" srcSet={WagApp} />
    <img className={cn('aspect-square md:max-w-[400px] lg:max-w-[450px] xl:max-w-[500px] rounded-md object-cover', imgClassName)} src={WagApp} loading="lazy" alt="app" />
  </picture>;