import { getErrorMessage } from '../../../utils/getErrorMessage';

const isString = (value: unknown) => typeof value === 'string';

export const handleError = (error) => {
  if (error?.response && error.response?.data) {
    const errorData = error.response?.data;
    const errorTitle = errorData && isString(errorData?.title) && errorData.title;
    const errorDetail = errorData && isString(errorData?.detail) && errorData.detail;
    if (errorDetail) {
      const errorsArray = errorData?.errors;
      if (errorDetail === 'Invalid request.' && !!errorsArray.length) {
        const errorArrayMessage = errorsArray[0]?.message;
        if (isString(errorArrayMessage)) {
          return errorArrayMessage;
        }
      }
      return `${errorDetail} - ${errorTitle}`;
    }
    if (errorTitle) {
      return errorTitle;
    }
  }
  return getErrorMessage(error);
};
