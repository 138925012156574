import { WalkerProfileFlags } from '../../../components/WalkerFilterFlags/data/profile-flags';
import { WAG_API_URL } from '../../../constants/endpoints';
import { GetWalkersByLocationResponse, ResponseWalkerProfile } from '../../../transformers/get-walkers-by-location-response-transformer';
import { snakeCaseToCamelCaseRecursive } from './helpers';
import { createSearchWalkersQuery } from './helpers/createSearchWalkersUrl';
import { AvailableServiceType } from '../../../containers/BookingJourney/components/whippet/data';
import { httpGet } from '../../../wag-react/common/api/api';

 type SortBy = {
  column: string;
  dir?: string;
}

const getRandomItem = (len: number) => Math.floor(Math.random() * len);

export type WalkerProfilesQueryParams = {
  latitude: number;
  longitude: number;
  distance?: number;
  page?: number;
  perPage?: number;
  sortBy?: [SortBy];
  service?: AvailableServiceType['value'];
  priceMin?: number;
  priceMax?: number;
  promoted?: boolean;
  datetimeMin?: string;
  datetimeMax?: string;
  walkerProfileFlags?: WalkerProfileFlags;
}

export const getProfiles = async (
  options: WalkerProfilesQueryParams) => {
  const walkerProfilesUrl = createSearchWalkersQuery(options);

  const {
    data: walkerProfilesData,
  } = await httpGet<GetWalkersByLocationResponse>(walkerProfilesUrl, {
    baseURL: WAG_API_URL,
  });

  const profiles: ResponseWalkerProfile[] = walkerProfilesData?.profiles.map(
    snakeCaseToCamelCaseRecursive);

  const calculateIndex = (index: number) => {
    if (walkerProfilesData.page === 1) return index;
    return index + ((walkerProfilesData.page - 1) * options.perPage);
  };

  return {
    ...walkerProfilesData,
    hasMoreProfiles: !!profiles && !!profiles.length,
    profiles: profiles?.map((profile, index) => ({
      ...profile,
      id: calculateIndex(index + 1),
      isPromoted: Boolean(options.promoted),
      rating: Math.ceil(profile?.rating * 10) / 10,
    })),
  };
};

type GetWalkerProfileOptions = {
  maxDistance?: boolean;
}

export const getWalkerProfiles = async (
  queryParams: WalkerProfilesQueryParams,
  options: GetWalkerProfileOptions = {
    maxDistance: false,
  },
) => {
  let [promotedProfiles, notPromotedProfiles] = await Promise.all([
    getProfiles({
      ...queryParams,
      page: 1,
      perPage: 20,
      promoted: true,
      sortBy: [{
        column: 'random',
      }],
    }),
    getProfiles(queryParams),
  ]);
  

  if (!promotedProfiles.profiles.length
      && !notPromotedProfiles.profiles.length && options.maxDistance) {
    [promotedProfiles, notPromotedProfiles] = await Promise.all([
      getProfiles({
        ...queryParams,
        distance: 50,
        page: 1,
        perPage: 20,
        promoted: true,
        sortBy: [{
          column: 'random',
        }],
      }),
      getProfiles({ ...queryParams, distance: 50 }),
    ]);
  }

  const randomPromotedProfile = () => {
    const promotedArr = [];

    if (promotedProfiles?.profiles?.length) {
      const randomIndex = getRandomItem(promotedProfiles?.profiles?.length);
      promotedArr.push(promotedProfiles?.profiles?.[randomIndex]);
    }

    return promotedArr as typeof notPromotedProfiles.profiles[number][];
  };

  return {
    page: promotedProfiles.page || notPromotedProfiles.page,
    maxNumberOfPages: notPromotedProfiles.maxNumberOfPages,
    prevPage: promotedProfiles.prevPage || notPromotedProfiles.prevPage,
    nextPage: promotedProfiles.nextPage || notPromotedProfiles.nextPage,
    hasMoreProfiles: notPromotedProfiles?.hasMoreProfiles,
    profiles: [
      ...randomPromotedProfile(),
      ...notPromotedProfiles.profiles?.map((profile) => ({
        ...profile,
        id: profile?.id + promotedProfiles.profiles?.length,
      })),
    ],
    state: promotedProfiles.state || notPromotedProfiles.state,
    zip: promotedProfiles.zip || notPromotedProfiles.zip,
  };
};
