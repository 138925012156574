'use client';

import { useEffect } from 'react';
import { LandingPagePathname, Partners, useBookingStore } from '../context/stores/booking-journey';

export const useSetAttribution = () => {
  const setAttribution = useBookingStore((state) => state.setAttribution);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const utmSource = searchParams.get('adid') ? 'google' : 'web';
    const pathname = window.location.pathname.replace(/\//, '');
    const isPartnersPage = pathname.includes('partners');

    setAttribution({
      adCreative: searchParams.get('campaignid') || '',
      adGroup: searchParams.get('adgroupid') || '',
      adid: searchParams.get('adid') || '',
      gclid: searchParams.get('gclid') || '',
      utmCampaign: isPartnersPage ? 'partners-page' : `${pathname as LandingPagePathname}-page`,
      utmSource: isPartnersPage ? `partner-${pathname.split('/').at(-1) as Partners}` : utmSource,
      utmMedium: 'booking-journey',
    });
  }, [setAttribution]);
};
