export const rollbarSkipPaths = [
  '/daily/',
  '/wellness/',
  '/product-guides',
  '/cat/condition',
  '/condition',
  '/training',
  '/treatment',
  '/sense',
  '/symptom',
  '/behavior',
  '/cat/symptom',
  // booking paths
  '/dog-boarding',
  '/dog-sitting',
  '/dog-walking',
  '/drop-ins',
  '/search',
  '/on-demand',
  '/walker-profile',
  '/pet-boarding',
  '/pet-sitting',
  '/pet-walking',
  '/pet-drop-ins',
] as const;
