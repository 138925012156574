import {
  PET_EXPERIENCE_SEARCH_FILTER,
  TRAINING_EXPERIENCE_SEARCH_FILTER,
  HOME_PREFERENCES_SEARCH_FILTER,
  HEALTH_WELLNESS_SEARCH_FILTER,
} from '.';

export const ALL_WALKER_PROFILE_FLAGS = [
  ...TRAINING_EXPERIENCE_SEARCH_FILTER,
  ...PET_EXPERIENCE_SEARCH_FILTER,
  ...HOME_PREFERENCES_SEARCH_FILTER,
  ...HEALTH_WELLNESS_SEARCH_FILTER,
] as const;

export type WalkerProfileFlags = {[K in WalkerProfileSlug]: boolean;}

export type WalkerProfileFlagsItems =
  | typeof TRAINING_EXPERIENCE_SEARCH_FILTER
  | typeof PET_EXPERIENCE_SEARCH_FILTER
  | typeof HOME_PREFERENCES_SEARCH_FILTER
  | typeof HEALTH_WELLNESS_SEARCH_FILTER

export type WalkerProfileSlug = typeof ALL_WALKER_PROFILE_FLAGS[number]['slug'];

export const initialWalkerProfileFlags = ALL_WALKER_PROFILE_FLAGS.reduce((acc, value) => {
  acc[value.slug] = false;
  return acc;
}, {} as {[K in WalkerProfileSlug]: boolean});
