import { QueryCache, QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { logError } from '../helpers/logError';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
      keepPreviousData: true,
    },
  },
  queryCache: new QueryCache({
    onError: (err, query) => {
      logError({ error: err, queryKey: query?.queryKey });
    },
  }),
});

const localStoragePersister = createSyncStoragePersister({
  storage: typeof window !== 'undefined' ? window.localStorage : undefined,
});

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});
