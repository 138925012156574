import clsx from 'clsx';
import React from 'react';
import { cn } from '../../../../../utils/tailwind';
export type HeroTitleProps = {
  containerClassName?: string;
  title?: string;
  titleClassName?: string;
  tagline?: string;
  taglineClassName?: string;
};
export const HeroTitle = ({
  containerClassName,
  title,
  tagline = 'Book a 5-star Pet Caregiver near you',
  titleClassName,
  taglineClassName
}: HeroTitleProps) => <div className={clsx('max-w-[400px] md:max-w-[1000px] mx-auto px-2 md:px-0', containerClassName)} data-sentry-component="HeroTitle" data-sentry-source-file="HeroTitle.tsx">
    <h1 className={cn('text-white text-left md:text-left max-w-[660px]', titleClassName)}>
      {title}
    </h1>

    <div className="flex flex-wrap justify-left my-3 md:justify-start items-center">
      <p className={cn('text-white ml-0 text-xs', taglineClassName)}>
        {tagline}
      </p>
    </div>
  </div>;