import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { cn } from '../../../../../utils/tailwind';
type PropType = PropsWithChildren<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>;
export const DotButton: React.FC<React.PropsWithChildren<PropType>> = props => {
  const {
    children,
    ...restProps
  } = props;
  return <button type="button" aria-label="Go to slide" {...restProps} data-sentry-component="DotButton" data-sentry-source-file="EmblaCarouselNav.tsx">
      {children}
    </button>;
};
const navButtonCommonClassName = clsx('appearance-none bg-transparent touch-manipulation flex no-underline pointer', 'z-[1] text-secondary justify-center items-center w-[2rem] h-[2rem] disabled:opacity-30');
export const PrevButton: React.FC<React.PropsWithChildren<PropType>> = ({
  children,
  ...restProps
}) => <button className={cn('embla__button embla__button--prev', navButtonCommonClassName)} aria-label="Previous" type="button" {...restProps} data-sentry-component="PrevButton" data-sentry-source-file="EmblaCarouselNav.tsx">
    <svg className="w-[65%]" viewBox="0 0 532 532" data-sentry-element="svg" data-sentry-source-file="EmblaCarouselNav.tsx">
      <path fill="currentColor" id="embla__arrow" d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z" data-sentry-element="path" data-sentry-source-file="EmblaCarouselNav.tsx" />
    </svg>
    {children}
  </button>;
export const NextButton: React.FC<React.PropsWithChildren<PropType>> = ({
  children,
  ...restProps
}) => <button className={clsx('embla__button embla__button--next', navButtonCommonClassName)} aria-label="Next" type="button" {...restProps} data-sentry-component="NextButton" data-sentry-source-file="EmblaCarouselNav.tsx">
    <svg className="w-[65%]" viewBox="0 0 532 532" data-sentry-element="svg" data-sentry-source-file="EmblaCarouselNav.tsx">
      <path fill="currentColor" id="embla__arrow" d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z" data-sentry-element="path" data-sentry-source-file="EmblaCarouselNav.tsx" />
    </svg>
    {children}
  </button>;